<!-- Data table without sortBy -->
<div class="container my-10">
  <div>
    <ngx-ov-data-table
      [identifier]="'DATA_TABLE_READONLY'"
      [title]="'BEVOEGDHEDEN'"
      [subTitle]="'Totaal aantal rijen: ' + _data.length"
      [settingsId]="'DATA_TABLE_READONLY'"
      [showSettings]="true"
      [data]="dataCustomFiltered()"
      [value]="_selected"
      [uniqueId]="'id'"
      [columns]="_columns"
      [sortable]="true"
      [filterable]="true"
      [selectable]="true"
      [pager]="_pager"
      [pagerPosition]="'top-bottom'"
      [detail]="true"
      [isDetailDefaultVisible]="false"
      [serversideFiltering]="false"
      (clearFilters)="onClearFilters()"
      (filterChange)="filterChange($event)"
      (serversideFilteringClick)="onServersideFilterChange($event)"
      (serversideSortingChange)="onServersideSortChange($event)"
      (dataChange)="dataChange($event)"
      (selectionChange)="dataChange($event)"
      [isLoading]="false"
      [allowChangeVisibleSettings]="true"
      [allowChangeFilterableSettings]="false"
      uniqueId="id"
    >
      <ng-template ngxOvDatatablePager>
        <ngx-ov-ui-pager
          [withDetailedNavigation]="false"
          [withInfo]="true"
          [withRows]="true"
          [pageSizes]="pageSizes"
          [pager]="_pager"
          (setPage)="setPage($event)"
          (setPageSize)="setPageSize($event)"
        ></ngx-ov-ui-pager>
      </ng-template>

      <ng-template ngxOvPanelActions>
        <ngx-ov-link
          [text]="'Download'"
          [iconAfter]="'fas fa-download'"
          (click)="onDownload()"
        ></ngx-ov-link>
      </ng-template>

      <ng-template ngxOvDatatableCustomFilter
        ><ngx-ov-input-field
          [label]="'Custom: Naam + Voornaam'"
          [ngModel]="customSearch1()"
          (ngModelChange)="customSearch1.set($event)"
        ></ngx-ov-input-field
      ></ng-template>
      <ng-template ngxOvDatatableCustomFilter
        ><ngx-ov-input-field
          [label]="'Custom filter 2'"
          [ngModel]="customSearch2()"
          (ngModelChange)="customSearch2.set($event)"
        ></ngx-ov-input-field
      ></ng-template>

      <ng-template ngxOvDataTableTemplate="naam" let-row
        >-{{ row.naam }} {{ row.voornaam }}-</ng-template
      >

      <ng-template ngxOvDataTableTemplate="vollediggevaccineerd" let-row>
        @if (row.vollediggevaccineerd != null) {
          <ngx-ov-checkbox
            [checked]="row.vollediggevaccineerd"
            (click)="row.vollediggevaccineerd = !row.vollediggevaccineerd"
          ></ngx-ov-checkbox>
        }
      </ng-template>

      <ng-template ngxOvDataTableTemplate="detail" let-row>
        <div style="height: 100px">Details {{ row.naam }}</div>
      </ng-template>
    </ngx-ov-data-table>
  </div>

  <br /><br />
  <ngx-ov-info-block
    [title]="'Tabel data in JSON formaat'"
    [icon]="'fas fa-table'"
    isCollapsed="true"
  >
    {{ _data | json }}
  </ngx-ov-info-block>
</div>
