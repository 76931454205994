import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { INgxOvDataTableColumn } from '../../../../projects/ngx-ov-ui/src/lib/components/data-visualisatie/data-table/data-table.utils';
import { JsonPipe } from '@angular/common';
import { NgxOvSelectComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/forms/select/select.component';
import { NgxOvDataTableTemplateDirective } from '../../../../projects/ngx-ov-ui/src/lib/components/data-visualisatie/data-table/directives/data-table-template.directive';
import { NgxOvDataTableComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/data-visualisatie/data-table/data-table.component';
import { NgxOvPanelComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/data-visualisatie/panel/panel.component';
import { PortalPanelActionsDirective } from '../../../../projects/ngx-ov-ui/src/lib/components/data-visualisatie/panel/portals/portal-panel-actions.directive';
import { NgxOvDataTableSettingsComponent } from '../../../../projects/ngx-ov-ui/src/lib/components/data-visualisatie/data-table/data-table-settings/data-table-settings.component';

export class Toets implements IToets {
  id: number;
  code: string;
  naam: string;
  vak: ICodeOmschrijving;
}
interface IToets {
  id: number;
  code: string;
  naam: string;
  vak: ICodeOmschrijving;
}

interface ICodeOmschrijving {
  code: string;
  omschrijving?: string;
}

@Component({
  selector: 'app-page-data-table-editable',
  templateUrl: './page-data-table-editable.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgxOvDataTableComponent,
    NgxOvDataTableTemplateDirective,
    NgxOvSelectComponent,
    JsonPipe,
    NgxOvPanelComponent,
    PortalPanelActionsDirective,
    NgxOvDataTableSettingsComponent
  ]
})
export class PageDataTableEditableComponent implements OnInit {
  vakken: Array<ICodeOmschrijving> = [
    {
      code: 'beg',
      omschrijving: 'Beginnersvak'
    },
    {
      code: 'mid',
      omschrijving: 'Iets moeilijker vak'
    },
    {
      code: 'exp',
      omschrijving: 'Expertenvak'
    }
  ];
  toetsenLijst: Array<IToets> = [
    {
      id: 0,
      code: '001',
      naam: 'Vak 0001',
      vak: this.vakken[0]
    },
    {
      id: 1,
      code: '002',
      naam: 'Vak 0002',
      vak: this.vakken[1]
    },
    {
      id: 2,
      code: '003',
      naam: 'Vak 0003',
      vak: this.vakken[2]
    }
  ];
  _columns: INgxOvDataTableColumn[] = [
    {
      name: 'code',
      label: 'Code toets',
      sortable: true
    },
    {
      name: 'naam',
      label: 'Naam toets',
      sortable: true
    },
    {
      name: 'vak.omschrijving',
      label: 'Vak',
      sortable: true
    }
  ];
  tableForm: UntypedFormGroup;

  constructor(
    private cd: ChangeDetectorRef,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.createForm();
  }

  public createForm() {
    this.tableForm = this.fb.group({
      toetsen: this.fb.array(
        this.toetsenLijst.map((t) => this.buildToetsForm(t))
      )
    });
  }
  get toetsen() {
    return this.tableForm?.controls['toetsen'] as UntypedFormArray;
  }

  buildToetsForm(toets: Toets): UntypedFormGroup {
    return this.fb.group({
      code: [toets.code],
      naam: [''],
      vak: [toets.vak.code, [Validators.required]]
    });
  }
}
