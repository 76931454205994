import {
  Component,
  EventEmitter,
  Inject,
  input,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  Self,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {
  FormsModule,
  NG_ASYNC_VALIDATORS,
  NG_VALIDATORS,
  NgControl,
  NgModel
} from '@angular/forms';
import { Dutch } from 'flatpickr/dist/l10n/nl';
import { english } from 'flatpickr/dist/l10n/default';
import { French } from 'flatpickr/dist/l10n/fr';
import { German } from 'flatpickr/dist/l10n/de';

import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import { CustomLocale, Locale } from 'flatpickr/dist/types/locale';
import { FormsBase } from '../forms-base';
import { NgxOvInputValidationComponent } from '../input-validation/input-validation.component';
import { FlatpickrModule } from 'angularx-flatpickr';
import { NgxOvInputLabelComponent } from '../input-label/input-label.component';
import { AsyncPipe, NgClass } from '@angular/common';
import { DisableEnableDate } from 'angularx-flatpickr/lib/flatpickr-defaults.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslationsService } from '../../../services/translations.service';
import { FlatPickrOutputOptions } from 'angularx-flatpickr/lib/flatpickr.directive';

let identifier = 0;

type DatepickerModeType = 'single' | 'multiple' | 'range';
type LanguageCodeType = 'nl' | 'en' | 'fr' | 'de';
type MonthSelectorType = 'static' | 'dropdown';

@Component({
  selector: 'ngx-ov-datepicker',
  templateUrl: './datepicker.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgxOvInputLabelComponent,
    FlatpickrModule,
    FormsModule,
    NgClass,
    NgxOvInputValidationComponent,
    AsyncPipe
  ]
})
export class NgxOvDatepickerComponent
  extends FormsBase<any[] | any>
  implements OnInit, OnChanges
{
  // Private variables
  showIcon = input<boolean>(true);

  private _allowInput: boolean;
  private _altFormat;
  private _altInput = true;
  private _classes: string;
  private _convertModelValue: boolean;
  private _dateFormat;
  private _defaultHour: number;
  private _defaultMinute: number;
  private _defaultSeconds: number;
  private _disable: DisableEnableDate[];
  private _disabled = false;
  private _disabledMobile = false;
  private _enable: DisableEnableDate[];
  private _enableSeconds = false;
  private _enableTime = false;
  private _hourIncrement = 1;
  private _isBlocked: boolean;
  private _isDisabled: boolean;
  private _isReadonly: boolean;
  private _label: string;
  private _maxDate: string | Date;
  private _minDate: string | Date;
  private _minuteIncrement = 5;
  private _mode: DatepickerModeType = 'single';
  private _noCalendar = false;
  private _time24hr = true;
  private _placeholder;
  private _monthYearOnly = false;
  private _languageCode: LanguageCodeType = 'nl';
  private _monthSelectorType: MonthSelectorType = 'dropdown';

  private _calendarInstance: any; // variable to store the instance reference

  // Custom inputs
  @Input() set classes(value: string) {
    this._classes = value;
  }
  get classes(): string {
    return this._classes;
  }

  @Input() set isBlocked(value: boolean) {
    this._isBlocked = value;
  }
  get isBlocked(): boolean {
    return this._isBlocked;
  }

  @Input() set isDisabled(value: boolean) {
    this._isDisabled = value;
  }
  get isDisabled(): boolean {
    return this._isDisabled;
  }

  @Input() set isReadonly(value: boolean) {
    this._isReadonly = value;
  }
  get isReadonly(): boolean {
    return this._isReadonly;
  }

  @Input() set label(value: string) {
    this._label = value;
  }
  get label(): string {
    return this._label;
  }

  // Inputs from datepicker
  @Input() set allowInput(value: boolean) {
    this._allowInput = value;
  }
  get allowInput(): boolean {
    return this._allowInput;
  }

  @Input() set altFormat(value: string) {
    this._altFormat = value;
  }
  get altFormat(): string {
    if (this._monthYearOnly) {
      return this._altFormat ? this._altFormat : 'F Y';
    }
    return this._altFormat ? this._altFormat : 'd-m-Y';
  }

  @Input() set altInput(value: boolean) {
    this._altInput = value;
  }
  get altInput(): boolean {
    return this._altInput;
  }

  @Input() set convertModelValue(value: boolean) {
    this._convertModelValue = value;
  }
  get convertModelValue(): boolean {
    return this._convertModelValue;
  }

  @Input() set dateFormat(value: string) {
    this._dateFormat = value;
  }
  get dateFormat(): string {
    if (this._monthYearOnly) {
      return this._dateFormat ? this._dateFormat : 'm-y';
    }
    return this._dateFormat ? this._dateFormat : 'd-m-Y';
  }

  @Input() set defaultHour(value: number) {
    if (value >= 0 && value <= 24) {
      this._defaultHour = value;
    }
  }
  get defaultHour(): number {
    return this._defaultHour;
  }

  @Input() set defaultMinute(value: number) {
    if (value >= 0 && value < 60) {
      this._defaultMinute = value;
    }
  }
  get defaultMinute(): number {
    return this._defaultMinute;
  }

  @Input() set defaultSeconds(value: number) {
    if (value >= 0 && value < 60) {
      this._defaultSeconds = value;
    }
  }
  get defaultSeconds(): number {
    return this._defaultSeconds;
  }

  @Input() set disable(value: DisableEnableDate[]) {
    this._disable = value;
  }
  get disable(): DisableEnableDate[] {
    return this._disable;
  }

  @Input() set disabled(value: boolean) {
    this._disabled = value;
  }
  get disabled(): boolean {
    return this._disabled;
  }

  @Input() set disabledMobile(value: boolean) {
    this._disabledMobile = value;
  }
  get disabledMobile(): boolean {
    return this._disabledMobile;
  }

  @Input() set enable(value: DisableEnableDate[]) {
    this._enable = value;
  }

  get enable(): DisableEnableDate[] {
    return this._enable;
  }

  @Input() set enableSeconds(value: boolean) {
    this._enableSeconds = value;
  }
  get enableSeconds(): boolean {
    return this._enableSeconds;
  }

  @Input() set enableTime(value: boolean) {
    this._enableTime = value;
  }
  get enableTime(): boolean {
    return this._enableTime;
  }

  @Input() set hourIncrement(value: number) {
    if (value > 0 && value < 24) {
      this._hourIncrement = value;
    }
  }
  get hourIncrement(): number {
    return this._hourIncrement;
  }

  @Input() set maxDate(value: string | Date) {
    this._maxDate = value;
  }
  get maxDate(): string | Date {
    return this._maxDate;
  }

  @Input() set minDate(value: string | Date) {
    this._minDate = value;
  }
  get minDate(): string | Date {
    return this._minDate;
  }

  @Input() set minuteIncrement(value: number) {
    if (value > 0 && value < 60) {
      this._minuteIncrement = value;
    }
  }
  get minuteIncrement(): number {
    return this._minuteIncrement;
  }

  @Input() set mode(value: DatepickerModeType) {
    this._mode = value;
  }
  get mode(): DatepickerModeType {
    return this._mode;
  }

  @Input() set noCalendar(value: boolean) {
    this._noCalendar = value;
  }
  get noCalendar(): boolean {
    return this._noCalendar;
  }

  @Input() set time24hr(value: boolean) {
    this._time24hr = value;
  }
  get time24hr(): boolean {
    return this._time24hr;
  }

  @Input() set placeholder(value: string) {
    this._placeholder = value;
  }
  get placeholder(): string {
    return this._placeholder;
  }

  @Input() set monthYearOnly(value: boolean) {
    this._monthYearOnly = value;
  }

  get monthYearOnly(): boolean {
    return this._monthYearOnly;
  }

  @Input() set lang(value: LanguageCodeType) {
    this._languageCode = value;
  }

  get lang(): LanguageCodeType {
    return this._languageCode;
  }

  get locale(): Locale | CustomLocale {
    return this.getLanguage();
  }

  @Input() set monthSelectorType(value: MonthSelectorType) {
    this._monthSelectorType = value;
  }

  get monthSelectorType(): MonthSelectorType {
    return this._monthSelectorType;
  }

  // Outputs

  @Output() flatpickrChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(NgModel, { static: true }) model: NgModel;

  public identifier = `form-datepicker-${(identifier += 1)}`;

  public plugins = [];

  // -------------------------------------------------------------------------
  // Constructor
  // -------------------------------------------------------------------------
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
    @Self() @Optional() ngControl: NgControl,
    @Optional() translateService: TranslateService,
    translationsService: TranslationsService
  ) {
    super(
      validators,
      asyncValidators,
      ngControl,
      translateService,
      translationsService
    );
  }

  ngOnInit(): void {
    if (this.monthYearOnly) {
      this.plugins.push(
        monthSelectPlugin({
          shorthand: true,
          dateFormat: this.dateFormat,
          altFormat: this.altFormat,
          theme: 'light'
        })
      );
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    super.init();

    if (changes && this._calendarInstance) {
      if (changes.minDate) {
        this._calendarInstance.config.minDate = changes.minDate.currentValue;
        this.redraw();
      }

      if (changes.maxDate) {
        this._calendarInstance.config.maxDate = changes.maxDate.currentValue;
        this.redraw();
      }

      if (changes.enable) {
        this._calendarInstance.config.enable = changes.enable.currentValue;
        this.redraw();
      }

      if (changes.disable) {
        this._calendarInstance.config.disable = changes.disable.currentValue;
        this.redraw();
      }
    }
  }

  getLanguage(): Locale | CustomLocale {
    switch (this._languageCode) {
      case 'nl': {
        return Dutch;
      }
      case 'en': {
        return english;
      }
      case 'fr': {
        return French;
      }
      case 'de': {
        return German;
      }
      default: {
        return Dutch;
      }
    }
  }

  onFlatpickrClose(e: any) {
    if (e && e.instance && e.instance.altInput && !e.instance.altInput.value) {
      this.value = null;
    }

    // Manual input trigger a parse of manual input to update the datePicker value
    if (e?.instance?.altInput?.value && this.allowInput) {
      // This is a setDate(date, triggerChange, format) function declared on the FlatPickrOutputOptions -> instance.
      // Since this is an Any object the IDE doesn't know it exists.
      e.instance.setDate(e.instance.altInput.value, true, this.altFormat);
    }
    this.touch();
  }

  isDatepickerPristine(): boolean {
    return this.model ? this.model?.pristine : true;
  }

  getDefaultHour(): number {
    if (!this.enableTime) {
      return null;
    }

    return this.defaultHour ? this.defaultHour : new Date().getHours();
  }

  getDefaultMinute(): number {
    if (!this.enableTime) {
      return null;
    }
    return this.defaultMinute ? this.defaultMinute : new Date().getMinutes();
  }

  getDefaultSeconds(): number {
    if (!this.enableTime || !this.enableSeconds) {
      return null;
    }
    return this.defaultSeconds ? this.defaultSeconds : new Date().getSeconds();
  }

  onFlatpickrChange(e: any) {
    this.touch();
    this.flatpickrChange.emit(e);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onBlur(value: string): void {
    this.touch();
  }

  onFlatpickrReady($event: FlatPickrOutputOptions) {
    this._calendarInstance = $event.instance;
  }

  //When the min or max date changes of the flatpickr instance
  //The component is not redrawn, thus still showing wrong enabled/disabled dates.
  //This is a workaround that triggers on a redraw (which happens in the 'onClose' method).
  //This code can be removed once the issue has been solved.
  //See: https://github.com/flatpickr/flatpickr/issues/1989
  redraw() {
    if (!this._calendarInstance) {
      return;
    }

    const hooks = this._calendarInstance?.config['onClose'];
    if (hooks !== undefined && hooks.length > 0) {
      for (var i = 0; hooks[i] && i < hooks.length; i++)
        hooks[i](
          this._calendarInstance?.selectedDates,
          this._calendarInstance?.input.value,
          this._calendarInstance,
          null
        );
    }
  }
}
