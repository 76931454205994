@if (columns()?.length) {
  <div class="c-data-table-settings">
    <ngx-ov-link
      [text]="'Wijzig tabelinstellingen'"
      [iconAfter]="'fas fa-sliders'"
      (click)="toggleSettings()"
      [attr.data-cy]="'change-data-table-settings'"
    ></ngx-ov-link>
    @if (settingsVisible()) {
      <div class="c-flyout__overlay" (click)="toggleSettings()"></div>
      <div class="c-data-table-settings__content">
        <table>
          <caption>
            Instellingen voor datatable
          </caption>
          <tr>
            <th scope="col">Kolom</th>
            @if (allowChangeVisibleSettings()) {
              <th scope="col">Zichtbaar</th>
            }
            @if (allowChangeFilterableSettings()) {
              <th scope="col">Filterbaar</th>
            }
          </tr>
          @for (column of columns(); track column.name) {
            <tr>
              <td
                class="c-data-table-settings__label"
                [innerHTML]="column.label"
              ></td>
              @if (allowChangeVisibleSettings()) {
                <td>
                  <ngx-ov-checkbox
                    [(ngModel)]="column.visible"
                    (ngModelChange)="updateSettings(columns())"
                    [attr.data-cy]="
                      'change-data-table-settings-checkbox-visible-' +
                      column.name
                    "
                  ></ngx-ov-checkbox>
                </td>
              }
              @if (allowChangeFilterableSettings()) {
                <td>
                  <ngx-ov-checkbox
                    [(ngModel)]="column.filterable"
                    (ngModelChange)="updateSettings(columns())"
                    [isDisabled]="column.filterableDisabled"
                    [attr.data-cy]="
                      'change-data-table-settings-checkbox-filterable-' +
                      column.name
                    "
                  ></ngx-ov-checkbox>
                </td>
              }
            </tr>
          }
        </table>
      </div>
    }
  </div>
}
