import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnChanges,
  Optional,
  Output,
  Self,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  FormsModule,
  NG_ASYNC_VALIDATORS,
  NG_VALIDATORS,
  NgControl,
  NgModel,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { FormsBase } from '../forms-base';
import { NgxOvUiConfigService } from '../../../ngx-ov-ui.config.service';
import { format, isBefore, isValid } from 'date-fns';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslationsService } from '../../../services/translations.service';
import { NgxOvDatepickerInputsService } from './datepicker-inputs.service';
import { NgxOvInputValidationComponent } from '../input-validation/input-validation.component';
import { NgxOvExtraInfoComponent } from '../extra-info/extra-info.component';
import { AsyncPipe } from '@angular/common';
import { NgxOvInputLabelComponent } from '../input-label/input-label.component';
import { NgxMaskDirective } from 'ngx-mask';

let identifier = 0;

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-datepicker-inputs',
  templateUrl: './datepicker-inputs.component.html',
  providers: [],
  standalone: true,
  imports: [
    FormsModule,
    NgxOvInputLabelComponent,
    ReactiveFormsModule,
    NgxOvExtraInfoComponent,
    NgxOvInputValidationComponent,
    TranslateModule,
    NgxMaskDirective
  ]
})
export class NgxOvDatepickerInputsComponent
  extends FormsBase<string>
  implements OnChanges
{
  // -------------------------------------------------------------------------
  // private variables
  // -------------------------------------------------------------------------
  private _classes: string;
  private _maxDate: string;
  private _minDate: string;
  private _monthYearOnly: boolean;
  private _label: string;
  private _isDisabled: boolean;
  private _isReadonly: boolean;
  private _id: string;
  private _dateFormat: string;
  _dayText = 'Dag';
  _monthText = 'Maand';
  _yearText = 'Jaar';
  readOnlyText = '';

  @ViewChild('inputDay') inputDay;
  @ViewChild('inputMonth') inputMonth;
  @ViewChild('inputYear') inputYear;

  private _internalValidationErrors: string = '';

  // -------------------------------------------------------------------------
  // Getters / setters
  // -------------------------------------------------------------------------
  get classes(): string {
    return this._classes;
  }
  @Input() set classes(classes: string) {
    this._classes = classes;
  }

  get maxDate(): string {
    return this._maxDate;
  }
  @Input() set maxDate(maxDate: string) {
    this._maxDate = maxDate;
  }

  get minDate(): string {
    return this._minDate;
  }
  @Input() set minDate(minDate: string) {
    this._minDate = minDate;
  }

  get monthYearOnly(): boolean {
    return !!this._monthYearOnly;
  }
  @Input() set monthYearOnly(monthYearOnly: boolean) {
    this._monthYearOnly = monthYearOnly;
  }

  get label(): string {
    return this._label;
  }
  @Input() set label(label: string) {
    this._label = label;
  }

  get isDisabled(): boolean {
    return !!this._isDisabled;
  }
  @Input() set isDisabled(isDisabled: boolean) {
    this._isDisabled = isDisabled;
  }

  get tabindex(): number {
    if (this._isDisabled) {
      return -1;
    }
    return null;
  }

  get isReadonly(): boolean {
    return !!this._isReadonly;
  }
  @Input() set isReadonly(isReadonly: boolean) {
    this._isReadonly = isReadonly;
  }

  get id(): string {
    return this._id ? this._id : this.identifier;
  }

  @Input() set id(id: string) {
    this._id = id;
  }

  get dateFormat(): string {
    return this._dateFormat ? this._dateFormat : 'dd/MM/yyyy';
  }

  @Input() set dateFormat(dateFormat: string) {
    this._dateFormat = dateFormat;
  }

  get internalValidationErrors(): string {
    return this._internalValidationErrors;
  }

  set internalValidationErrors(internalValidationErrors: string) {
    this._internalValidationErrors = internalValidationErrors;
  }

  @Output() invalidInput = new EventEmitter();
  @ViewChild(NgModel, { static: true }) model: NgModel;

  @HostListener('blur')
  onBlurHost() {
    console.log('onBlurHost');
  }

  public identifier = `form-datepicker-inputs-${(identifier += 1)}`;

  datePickerForm: UntypedFormGroup = new UntypedFormGroup({
    day: new UntypedFormControl('', [Validators.min(1)]),
    month: new UntypedFormControl('', [Validators.min(1)]),
    year: new UntypedFormControl('')
  });

  // -------------------------------------------------------------------------
  // Constructor
  // -------------------------------------------------------------------------

  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
    @Self() @Optional() ngControl: NgControl,
    private datepickerInputsService: NgxOvDatepickerInputsService,
    public config: NgxOvUiConfigService,
    translationsService: TranslationsService,
    private cdr: ChangeDetectorRef,
    @Optional() translateService: TranslateService
  ) {
    super(
      validators,
      asyncValidators,
      ngControl,
      translateService,
      translationsService
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    super.init();
    this.getCopy();
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'isDisabled': {
            if (this.isDisabled) {
              this.datePickerForm.get('day').disable();
              this.datePickerForm.get('month').disable();
              this.datePickerForm.get('year').disable();
            } else {
              this.datePickerForm.get('day').enable();
              this.datePickerForm.get('month').enable();
              this.datePickerForm.get('year').enable();
            }
          }
        }
      }
    }
    this.readOnlyText = this.createReadonlyText();
  }

  get day() {
    return this.monthYearOnly ? '01' : this.datePickerForm.get('day').value;
  }

  get month() {
    return this.datePickerForm.get('month').value;
  }

  get year() {
    return this.datePickerForm.get('year').value;
  }

  getCopy() {
    this._dayText = this.getNgxOvUiTranslation('DAY');
    this._monthText = this.getNgxOvUiTranslation('MONTH');
    this._yearText = this.getNgxOvUiTranslation('YEAR');
  }

  initControls(value: any) {
    if (this.monthYearOnly) {
      this.datePickerForm = new UntypedFormGroup({
        day: new UntypedFormControl(''),
        month: new UntypedFormControl('', [Validators.min(1)]),
        year: new UntypedFormControl('')
      });
    } else {
      this.datePickerForm = new UntypedFormGroup({
        day: new UntypedFormControl('', [Validators.min(1)]),
        month: new UntypedFormControl('', [Validators.min(1)]),
        year: new UntypedFormControl('')
      });
    }

    if (
      value != null &&
      isValid(this.datepickerInputsService.parseDate(value, this.dateFormat))
    ) {
      this.splitDateToFields(value);
    }
  }

  clearDateFields() {
    this.datePickerForm.get('day').patchValue(this.monthYearOnly ? '1' : '');
    this.datePickerForm.get('month').patchValue('');
    this.datePickerForm.get('year').patchValue('');
  }

  createReadonlyText() {
    return this.getValue()
      ? this.datepickerInputsService.formatDate(
          this.getValue(),
          this.dateFormat,
          this.monthYearOnly
        )
      : '-';
  }

  splitDateToFields(dateStringToSplit: string) {
    let parsedDate = this.datepickerInputsService.parseDate(
      dateStringToSplit,
      this.dateFormat
    );
    if (this.monthYearOnly) {
      this.datePickerForm.get('day').patchValue('01');
    } else {
      this.datePickerForm.get('day').patchValue(format(parsedDate, 'dd'));
    }

    this.datePickerForm.get('month').patchValue(format(parsedDate, 'MM'));
    this.datePickerForm.get('year').patchValue(format(parsedDate, 'yyyy'));
    this.readOnlyText = this.createReadonlyText();
  }

  registerModelChange() {
    this.initControls(this.model.value);
  }

  private getValue() {
    try {
      if (this.year && this.month && this.day) {
        const inputDate = new Date(+this.year, +this.month - 1, +this.day);
        return format(inputDate, this.dateFormat);
      }

      return null;
    } catch (err) {
      return null;
    }
  }

  checkForValidationErrors(inputDate: Date): boolean {
    if (
      !this.isValidDate(inputDate) &&
      this.datePickerForm.get('day').valid &&
      this.datePickerForm.get('month').valid &&
      this.datePickerForm.get('month').value?.length &&
      this.datePickerForm.get('year').valid &&
      this.datePickerForm.get('year').value?.length == 4
    ) {
      this.internalValidationErrors =
        this.getNgxOvUiTranslation('INVALID_DATE');
      return false;
    } else if (!this.datePickerForm.get('day').valid) {
      this.internalValidationErrors =
        this.getNgxOvUiTranslation('INVALID_DATE_DAY');
      return false;
    } else if (!this.datePickerForm.get('month').valid) {
      this.internalValidationErrors =
        this.getNgxOvUiTranslation('INVALID_DATE_MONTH');
      return false;
    }
    if (this.allInputsHaveValue()) {
      if (
        isBefore(
          inputDate,
          this.datepickerInputsService.parseDate(this.minDate, this.dateFormat)
        )
      ) {
        this.internalValidationErrors = this.getNgxOvUiTranslation(
          'DATE_TOO_EARLY'
        ).replace(
          '{minDate}',
          this.datepickerInputsService.formatDate(
            this.minDate,
            this.dateFormat,
            this.monthYearOnly
          )
        );
        return false;
      }

      if (
        isBefore(
          this.datepickerInputsService.parseDate(this.maxDate, this.dateFormat),
          inputDate
        )
      ) {
        this.internalValidationErrors = this.getNgxOvUiTranslation(
          'DATE_TOO_LATE'
        ).replace(
          '{maxDate}',
          this.datepickerInputsService.formatDate(
            this.maxDate,
            this.dateFormat,
            this.monthYearOnly
          )
        );
        return false;
      }
    }

    return true;
  }

  isValidDate(date) {
    return date.getFullYear() == this.year &&
      date.getMonth() == +this.month - 1 &&
      date.getDate() == this.day
      ? true
      : false;
  }

  // -------------------------------------------------------------------------
  // Translation
  // -------------------------------------------------------------------------
  getNgxOvUiTranslation(code: string) {
    const translationKey = `FORMS.DATEPICKER_INPUTS.${code.toUpperCase()}`;
    return this.translationsService.getDefaultComponentTranslation(
      translationKey
    );
  }

  gotoNext(e: KeyboardEvent, input: string): void {
    if (e.key) {
      if (e.key === '/') {
        if (input === 'DAY') {
          this.inputMonth.nativeElement.focus();
        } else if (input === 'MONTH') {
          this.inputYear.nativeElement.focus();
        }
      }
    }
  }

  writeValue(value: string) {
    this.initControls(value);
  }

  onBlur(value: string): void {
    this.internalValidationErrors = '';
    if (this.monthYearOnly) {
      // this.datePickerForm.get('day').patchValue('01');
    }
    if (this.month || this.year) {
      this.value = this.getValue();
      const inputDate = new Date(+this.year, +this.month - 1, +this.day);
      if (!this.checkForValidationErrors(inputDate) && this.ngControl?.control) {
        this.ngControl.control?.setErrors({ incorrect: true });
      }
    }
    this.readOnlyText = this.createReadonlyText();
    if (this.allInputsHaveValue()) {
      if (
        value == null ||
        !this.isValidDate(
          this.datepickerInputsService.parseDate(value, this.dateFormat)
        )
      ) {
        this.touch();
      } else {
        this.touch();
        this.writeValue(value);
      }
    }
  }

  allInputsHaveValue(): boolean {
    if (this._monthYearOnly) {
      return (
        this.datePickerForm.get('month').value &&
        this.datePickerForm.get('year').value &&
        this.datePickerForm.get('year').value.length === 4
      );
    }
    return (
      this.datePickerForm.get('day').value &&
      this.datePickerForm.get('month').value &&
      this.datePickerForm.get('year').value &&
      this.datePickerForm.get('year').value.length === 4
    );
  }
}
