import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Optional,
  Output,
  Self,
  ViewChild
} from '@angular/core';
import {
  FormsModule,
  NG_ASYNC_VALIDATORS,
  NG_VALIDATORS,
  NgControl,
  NgModel
} from '@angular/forms';
import { FormsBase } from '../forms-base';
import { NgxOvInputValidationComponent } from '../input-validation/input-validation.component';
import { NgxOvExtraInfoComponent } from '../extra-info/extra-info.component';

import { NgxOvInputLabelComponent } from '../input-label/input-label.component';
import { CommonModule } from '@angular/common';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { TranslateService } from '@ngx-translate/core';
import { TranslationsService } from '../../../services/translations.service';

let identifier = 0;

// =============================================================================
// Component
// =============================================================================
@Component({
  selector: 'ngx-ov-input-field',
  templateUrl: './input-field.html',
  providers: [],
  standalone: true,
  imports: [
    NgxOvInputLabelComponent,
    FormsModule,
    NgxMaskPipe,
    NgxMaskDirective,
    NgxOvExtraInfoComponent,
    NgxOvInputValidationComponent,
    CommonModule
  ]
})
export class NgxOvInputFieldComponent
  extends FormsBase<string>
  implements OnChanges
{
  // -------------------------------------------------------------------------
  // private variables
  // -------------------------------------------------------------------------
  private _classes: string;
  private _label: string;
  private _isDisabled = false;
  private _isReadonly = false;
  private _isReadonlyEmptyText = '-';
  private _placeholder: string;
  private _maskExpr: string;
  private _thousandSeparator: '.' | ',' = '.';
  private _decimalMarker: '.' | ',' = ',';
  private _allowNegativeNumbers = false;
  private _dropSpecialCharacters = true;
  private _suffix: string;
  private _prefix: string;
  private _id: string;
  private _extraInfo: string;
  private _autocomplete = 'off';

  private _hideTextPassword: boolean = false;
  private _isPassword: boolean = false;

  private _patterns;

  // -------------------------------------------------------------------------
  // Getters / setters
  // -------------------------------------------------------------------------
  get classes(): string {
    return this._classes;
  }
  @Input() set classes(classes: string) {
    this._classes = classes;
  }

  get label(): string {
    return this._label;
  }
  @Input() set label(label: string) {
    this._label = label;
  }

  // autocomplete values (https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#values)
  get autocomplete(): string {
    return this._autocomplete;
  }
  @Input() set autocomplete(autocomplete: string) {
    this._autocomplete = autocomplete;
  }

  get isDisabled(): boolean {
    return this._isDisabled;
  }
  @Input() set isDisabled(isDisabled: boolean) {
    this._isDisabled = isDisabled;
  }

  get tabindex(): number {
    if (this._isDisabled) {
      return -1;
    }
    return null;
  }

  get isReadonly(): boolean {
    return this._isReadonly;
  }
  @Input() set isReadonly(isReadonly: boolean) {
    this._isReadonly = isReadonly;
  }

  get isReadonlyEmptyText(): string {
    return this._isReadonlyEmptyText;
  }
  @Input() set isReadonlyEmptyText(isReadonlyEmptyText: string) {
    this._isReadonlyEmptyText = isReadonlyEmptyText;
  }

  get placeholder(): string {
    return this._placeholder ? this._placeholder : '';
  }
  @Input() set placeholder(placeholder: string) {
    this._placeholder = placeholder;
  }

  // ngx-mask (https://www.npmjs.com/package/ngx-mask)
  get maskExpr(): string {
    return this._maskExpr;
  }
  @Input() set maskExpr(maskExpr: string) {
    this._maskExpr = maskExpr;
  }

  // ngx-mask (https://www.npmjs.com/package/ngx-mask)
  get patterns(): string {
    return this._patterns;
  }
  @Input() set patterns(patterns: string) {
    this._patterns = patterns;
  }

  // ngx-mask (https://www.npmjs.com/package/ngx-mask)
  get thousandSeparator(): '.' | ',' {
    return this._thousandSeparator;
  }

  @Input() set thousandSeparator(thousandSeparator: '.' | ',') {
    this._thousandSeparator = thousandSeparator;
  }

  // ngx-mask (https://www.npmjs.com/package/ngx-mask)
  get decimalMarker(): '.' | ',' {
    return this._decimalMarker;
  }

  @Input() set decimalMarker(decimalMarker: '.' | ',') {
    this._decimalMarker = decimalMarker;
  }

  get allowNegativeNumbers(): boolean {
    return this._allowNegativeNumbers;
  }

  @Input() set allowNegativeNumbers(allowNegativeNumbers) {
    this._allowNegativeNumbers = allowNegativeNumbers;
  }

  get dropSpecialCharacters(): boolean {
    return this._dropSpecialCharacters;
  }
  @Input() set dropSpecialCharacters(dropSpecialCharacters: boolean) {
    this._dropSpecialCharacters = dropSpecialCharacters;
  }

  get suffix(): string {
    return this._suffix;
  }
  @Input() set suffix(suffix: string) {
    this._suffix = suffix;
  }

  get prefix(): string {
    return this._prefix;
  }
  @Input() set prefix(prefix: string) {
    this._prefix = prefix;
  }

  get extraInfo(): string {
    return this._extraInfo;
  }
  @Input() set extraInfo(extraInfo: string) {
    this._extraInfo = extraInfo;
  }

  get id(): string {
    return this._id ? this._id : this.identifier;
  }
  @Input() set id(id: string) {
    this._id = id;
  }

  get hideTextPassword(): boolean {
    return this._hideTextPassword;
  }

  @Input() set hideTextPassword(value: boolean) {
    this._hideTextPassword = value;
  }

  get isPassword(): boolean {
    return this._isPassword;
  }

  @Input() set isPassword(value: boolean) {
    this._isPassword = value;
    this._hideTextPassword = true;
  }

  @Output() invalidInput = new EventEmitter();
  @ViewChild(NgModel, { static: true }) model: NgModel;
  @ViewChild('inputElement') inputElement: ElementRef;

  public identifier = `form-text-${(identifier += 1)}`;

  // -------------------------------------------------------------------------
  // Constructor
  // -------------------------------------------------------------------------
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
    @Self() @Optional() ngControl: NgControl,
    @Optional() translateService: TranslateService,
    translationsService: TranslationsService
  ) {
    super(
      validators,
      asyncValidators,
      ngControl,
      translateService,
      translationsService
    );
  }

  ngOnChanges() {
    super.init();
  }

  /**
   * Implemented as part of ControlValueAccessor.
   */
  writeValue(value: string) {
    if (value && typeof value === 'string') {
      this.value = value.trim();
    } else {
      this.value = value;
    }
  }

  onBlur(value: string): void {
    this.touch();
    this.writeValue(value);
  }

  changeHideTextPassword() {
    this.hideTextPassword = !this.hideTextPassword;
  }

  focusInput() {
    this.inputElement.nativeElement.focus();
  }
}
