import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  HostListener,
  inject,
  Input,
  signal
} from '@angular/core';
import { INgxOvDataTableColumn } from '../data-table.utils';
import { JsonPipe, NgForOf } from '@angular/common';
import { NgxOvCheckboxComponent } from '../../../forms/checkbox/checkbox-item/checkbox.component';
import { FormsModule } from '@angular/forms';
import { NgxOvDataTableSettingsService } from './data-table-settings.service';
import { trackItemByIndex } from '../../../../helper';
import { NgxOvFlyoutItemComponent } from '../../../navigatie/flyout/flyout-item.component';
import { NgxOvFlyoutComponent } from '../../../navigatie/flyout/flyout.component';
import { NgxOvLinkComponent } from '../../../default-layout-blokken/link/link.component';
import { DataTableSettings } from './data-table-settings.model';

// =============================================================================
// Component
// =============================================================================
@Component({
  /* tslint:disable-next-line */
  selector: 'ngx-ov-data-table-settings',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'data-table-settings.component.html',
  imports: [
    JsonPipe,
    NgxOvCheckboxComponent,
    FormsModule,
    NgForOf,
    NgxOvFlyoutItemComponent,
    NgxOvFlyoutComponent,
    NgxOvLinkComponent
  ],
  standalone: true
})
export class NgxOvDataTableSettingsComponent {
  dataTableSettingsService = inject(NgxOvDataTableSettingsService);
  @Input() settingsId: string;

  columns = computed(() => {
    let settings: DataTableSettings =
      this.dataTableSettingsService.dataTableSettings();
    return settings
      ? settings[this.settingsId]?.overrideColumns().map((column) => {
          return {
            visible: column.visible !== false,
            ...column
          };
        })
      : [];
  });
  allowChangeVisibleSettings = computed(() => {
    const dataTableSettings: DataTableSettings =
      this.dataTableSettingsService.dataTableSettings();
    return dataTableSettings[this.settingsId]?.allowChangeVisibleSettings();
  });
  allowChangeFilterableSettings = computed(() => {
    const dataTableSettings: DataTableSettings =
      this.dataTableSettingsService.dataTableSettings();
    return dataTableSettings[this.settingsId]?.allowChangeFilterableSettings();
  });

  settingsVisible = signal(false);

  constructor(private eRef: ElementRef) {}

  updateSettings(column: INgxOvDataTableColumn[]) {
    this.dataTableSettingsService.setDataTableSettings(this.settingsId, column);
  }

  protected readonly trackByFn = trackItemByIndex;

  toggleSettings() {
    if (this.settingsVisible()) {
      this.settingsVisible.set(false);
    } else {
      this.settingsVisible.set(true);
    }
  }

  @HostListener('document:click', ['$event'])
  @HostListener('document:touchstart', ['$event'])
  onClickBuitenComponent(event: any) {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.settingsVisible.set(false);
    }
  }
}
