<!--
  Util classes voor formulieren:
    -> u-form-show-optional: toont optioneel marker
    -> u-form-hide-required: verbergt required marker
-->

<div class="container u-form-show-optional p-small">
  <div>
    <div class="grid grid-cols-12 gap-medium">
      <div class="col-span-12">
        <h2>Reactive form</h2>
      </div>
    </div>

    <div class="grid grid-cols-12 gap-medium">
      <div
        class="col-span-12 tablet:col-span-3 tablet:col-start-9 tablet:order-last"
      >
        <div>
          <h6>Jouw inschrijving</h6>
        </div>
        <div
          class="border-solid border-x-0 border-y-1 border-grayscales-gray4 py-small"
        >
          <p>> stap 1: jouw gegevens</p>
          <p>> stap 2: jouw studie</p>
          <p>> stap 3: betalen</p>
        </div>
      </div>
      <form
        class="col-span-12 tablet:col-span-7"
        [formGroup]="form"
        novalidate
        (submit)="onSubmit()"
      >
        <div [ngClass]="{ 'u-form-readonly': isReadonlyForm }">
          <ngx-ov-panel
            [title]="'Vul hier je gegevens in'"
            [subTitle]="'subtitle'"
          >
            <ng-template ngxOvPanelActions>
              <div style="display: flex">
                <span>Readonly: &nbsp;</span>
                <ngx-ov-toggle
                  [formControlName]="'isReadonlyForm'"
                  [attr.data-cy]="'toggle-to-readonly'"
                ></ngx-ov-toggle>
              </div>
            </ng-template>

            <div class="col-span-12">
              <ngx-ov-input-readonly
                [attr.data-cy]="'input-field-readonly'"
                [label]="'Always readonly: currency'"
                [type]="'NUMBER'"
                [value]="'100000000,149'"
                prefix="$"
                [maskExpr]="'separator.2'"
                [thousandSeparator]="'.'"
              ></ngx-ov-input-readonly>
            </div>
            <div class="col-span-12">
              <ngx-ov-input-readonly
                [attr.data-cy]="'input-field-readonly'"
                [label]="'Always readonly'"
                [value]="'Voor form velden die altijd readonly zijn'"
              ></ngx-ov-input-readonly>
            </div>
            <div class="col-span-12">
              <ngx-ov-input-readonly
                [attr.data-cy]="'input-field-readonly'"
                [label]="'Always readonly: telefoon'"
                [value]="'0484579879'"
                [maskExpr]="'(0000) 000-000'"
                prefix="Tel:"
              ></ngx-ov-input-readonly>
            </div>
            <div class="col-span-12">
              <ngx-ov-input-readonly
                [attr.data-cy]="'input-field-readonly'"
                [label]="'Always readonly'"
                type="BOOLEAN"
                [value]="false"
              ></ngx-ov-input-readonly>
            </div>
            <div class="col-span-12">
              <ngx-ov-input-field
                [attr.data-cy]="'input-field-naam'"
                [formControlName]="'naam'"
                [autocomplete]="'family-name'"
                [label]="'Naam'"
                placeholder="Vul hier je naam in"
                [isReadonly]="isReadonlyForm"
              ></ngx-ov-input-field>
            </div>
            <div class="col-span-12">
              <ngx-ov-input-field
                [attr.data-cy]="'input-field-voornaam'"
                [formControlName]="'voornaam'"
                [autocomplete]="'given-name'"
                [label]="'Voornaam'"
                placeholder="Vul hier je voornaam in"
                [isReadonly]="isReadonlyForm"
              ></ngx-ov-input-field>
            </div>
            <div class="col-span-12">
              <ngx-ov-input-field
                [formControlName]="'email'"
                [label]="'E-mail'"
                [autocomplete]="'email'"
                placeholder="Vul hier je e-mail in"
                [isReadonly]="isReadonlyForm"
              ></ngx-ov-input-field>
            </div>
            <div class="col-span-12">
              <ngx-ov-input-field
                [formControlName]="'emailSuffix'"
                [label]="'E-mail (met suffix)'"
                [autocomplete]="'email'"
                placeholder="Vul hier je e-mail in"
                [isReadonly]="isReadonlyForm"
                [suffix]="'@vlaanderen.be'"
              ></ngx-ov-input-field>
            </div>
            <div class="col-span-12">
              <ngx-ov-input-field
                id="prijs"
                [formControlName]="'prijs'"
                [label]="'Prijs'"
                [prefix]="'€'"
                [isReadonly]="isReadonlyForm"
              ></ngx-ov-input-field>
            </div>
            <div class="col-span-12">
              <ngx-ov-textarea
                [label]="'Adres autocomplete'"
                [autocomplete]="'street-address'"
                [formControlName]="'opmerkingen'"
                [maxChar]="250"
                [isReadonly]="isReadonlyForm"
              ></ngx-ov-textarea>
            </div>
            <div class="col-span-12">
              {{ isReadonlyForm }}
              <ngx-ov-radio-group
                [formControlName]="'geslachtWithOptions'"
                [label]="'Wat is je geslacht? (options)'"
                [options]="geslachtenOptions"
                [bindLabel]="'omschrijving'"
                [bindValue]="['nested', 'code']"
                [bindIsDisabled]="'disabled'"
                [isReadonly]="isReadonlyForm"
                [radioGroupTooltip]="'kan ik dit zien met mijn keyboard?'"
              ></ngx-ov-radio-group>
            </div>
            <div class="col-span-12">
              <ngx-ov-radio-group
                [formControlName]="'geslacht'"
                [isReadonly]="isReadonlyForm"
                [label]="'Wat is je geslacht?'"
                [attr.data-cy]="'radio-geslacht'"
                id="geslacht"
              >
                <ngx-ov-radio
                  [isDisabled]="true"
                  [id]="'man'"
                  [value]="{ geslacht: 'man' }"
                  [label]="'man'"
                ></ngx-ov-radio>
                <ngx-ov-radio
                  [id]="'vrouw'"
                  [value]="{ geslacht: 'vrouw' }"
                  [label]="'vrouw'"
                ></ngx-ov-radio>
                <ngx-ov-radio
                  [id]="'ander'"
                  [value]="{ geslacht: 'ander' }"
                  [label]="'ander'"
                ></ngx-ov-radio>
              </ngx-ov-radio-group>
            </div>
            <div class="col-span-12">
              <ngx-ov-radio-group
                [formControlName]="'haarkleur'"
                [isReadonly]="isReadonlyForm"
                [label]="'Wat is je haarkleur?'"
                [showAsBlock]="true"
                [extraInfo]="'extra info'"
              >
                <ngx-ov-radio
                  [id]="'donker'"
                  [value]="'donker'"
                  [label]="'donker'"
                  [extraInfo]="'bruin/zwart'"
                ></ngx-ov-radio>
                <ngx-ov-radio
                  [id]="'blond'"
                  [value]="'blond'"
                  [label]="'blond'"
                  [isDisabled]="true"
                >
                  <ng-template ngxOvRadioContent
                    >Extra content voor radio</ng-template
                  >
                </ngx-ov-radio>
                <ngx-ov-radio
                  [id]="'ander'"
                  [value]="'ander'"
                  [label]="'ander'"
                >
                  <ng-template ngxOvRadioContent>
                    <ngx-ov-checkbox-group>
                      <ngx-ov-checkbox [label]="'test 1'">
                        <ng-template ngxOvCheckboxContent>
                          Content voor test 1
                        </ng-template>
                      </ngx-ov-checkbox>
                      <ngx-ov-checkbox [label]="'test 2'">
                        <ng-template ngxOvCheckboxContent>
                          Content voor test 2
                        </ng-template>
                      </ngx-ov-checkbox>
                    </ngx-ov-checkbox-group>
                  </ng-template>
                </ngx-ov-radio>
              </ngx-ov-radio-group>
            </div>
            <div class="col-span-12">
              <ngx-ov-radio-group
                [formControlName]="'vrije_dag'"
                [label]="'Vrije dag'"
                [options]="vrijeDagOptions"
                [isReadonly]="isReadonlyForm"
              ></ngx-ov-radio-group>
            </div>
            <div class="col-span-12">
              <ngx-ov-datepicker-inputs
                [formControlName]="'geboortedatumMonthYearObj'"
                [dateFormat]="'yyyy-MM-dd'"
                [label]="'Geboortedatum input'"
                [isReadonly]="isReadonlyForm"
                [isDisabled]="false"
                [maxDate]="maxDate"
                [minDate]="minDate"
                [monthYearOnly]="false"
                [attr.data-cy]="'datepicker-input-geboortedatum'"
              ></ngx-ov-datepicker-inputs>
            </div>
            <div class="col-span-12">
              <ngx-ov-datepicker-inputs
                [formControlName]="'geboortedatumMonthYearObj'"
                [dateFormat]="'yyyy-MM-dd'"
                [label]="'Diploma uitgereikt'"
                [isReadonly]="isReadonlyForm"
                [monthYearOnly]="true"
                [attr.data-cy]="'datepicker-input-geboortedatum-monthYearOnly'"
              ></ngx-ov-datepicker-inputs>
            </div>
            <div class="col-span-12">
              <ngx-ov-datepicker-inputs
                [formControlName]="'geboortedatumMonthYearObjEmpty'"
                [dateFormat]="'yyyy-MM-dd'"
                [label]="'Diploma uitgereikt'"
                [isReadonly]="isReadonlyForm"
                [monthYearOnly]="true"
                [attr.data-cy]="'datepicker-input-geboortedatum-monthYearOnly'"
              ></ngx-ov-datepicker-inputs>
            </div>
            <div class="col-span-12">
              <ngx-ov-datepicker
                [formControlName]="'geboortedatum'"
                [label]="'Geboortedatum'"
                [isReadonly]="isReadonlyForm"
                [dateFormat]="'d-m-Y'"
                [altFormat]="'d-m-Y'"
                [placeholder]=""
                [altInput]="true"
                [allowInput]="false"
                [enable]="enableDates"
                [attr.data-cy]="'datepicker-geboortedatum'"
              ></ngx-ov-datepicker>
            </div>
            <div class="col-span-12">
              <ngx-ov-datepicker
                [formControlName]="'periode'"
                [label]="'Periode'"
                [mode]="'range'"
                [isReadonly]="isReadonlyForm"
                [dateFormat]="'d-m-Y'"
                [altFormat]="'d-m-Y'"
                [placeholder]=""
                [altInput]="true"
                [allowInput]="false"
                [attr.data-cy]="'datepicker-periode'"
              ></ngx-ov-datepicker>
            </div>

            <div class="col-span-12">
              <ngx-ov-datepicker
                [formControlName]="'geboortedatummonthyearMin'"
                [label]="'Geboortedatum 2 - monthYearOnly - minDate voor andere component'"
                [isReadonly]="isReadonlyForm"
                [dateFormat]="'d-m-Y'"
                [altFormat]="'d/m/Y'"
                [placeholder]="'mm yyyy'"
                [altInput]="true"
                [allowInput]="true"
                [enable]="enableDates"
                [monthYearOnly]="true"
              ></ngx-ov-datepicker>
            </div>

            <div class="col-span-12">
              <ngx-ov-datepicker
                [formControlName]="'geboortedatummonthyear'"
                [label]="'Geboortedatum 2 - monthYearOnly'"
                [isReadonly]="isReadonlyForm"
                [dateFormat]="'d-m-Y'"
                [altFormat]="'d/m/Y'"
                [placeholder]="'mm yyyy'"
                [altInput]="true"
                [allowInput]="true"
                [monthYearOnly]="true"
                [minDate]="minEinddatum"
              ></ngx-ov-datepicker>
            </div>

            <div class="col-span-12">
              <ngx-ov-datepicker
                [formControlName]="'geboortedatummonthyearEnable'"
                [label]="'Geboortedatum 2 - monthYearOnly - enkel zomermaanden'"
                [isReadonly]="isReadonlyForm"
                [dateFormat]="'d-m-Y'"
                [altFormat]="'d/m/Y'"
                [placeholder]="'mm yyyy'"
                [altInput]="true"
                [allowInput]="true"
                [enable]="enableSummerDates"
                [monthYearOnly]="true"
              ></ngx-ov-datepicker>
            </div>

            <div class="col-span-12">
              <ngx-ov-datepicker
                [formControlName]="'afspraak'"
                [label]="'Datum en uur van afspraak'"
                [isReadonly]="isReadonlyForm"
                [enableTime]="true"
                [defaultHour]="11"
                [defaultMinute]="15"
                [dateFormat]="'d-m-Y H:i'"
                [altFormat]="'d-m-Y H:i'"
                [altInput]="true"
                [disable]="disabledDates"
              ></ngx-ov-datepicker>
            </div>
            <div class="col-span-12">
              <ngx-ov-datepicker
                [formControlName]="'afspraak'"
                [label]="'Uur van wekker'"
                [isReadonly]="isReadonlyForm"
                [enableTime]="true"
                [enableSeconds]="true"
                [noCalendar]="true"
                [defaultHour]="11"
                [defaultMinute]="15"
                [hourIncrement]="1"
                [minuteIncrement]="2"
                [dateFormat]="'H:i:S'"
                [altFormat]="'H:i:S'"
                [altInput]="true"
              ></ngx-ov-datepicker>
            </div>
            <div class="col-span-12"></div>
            <div class="col-span-3">
              <ngx-ov-input-field
                [formControlName]="'postcode'"
                label="Postcode:"
                placeholder="Postcode"
                [isReadonly]="isReadonlyForm"
              ></ngx-ov-input-field>
            </div>
            <div class="col-span-9">
              <ngx-ov-input-field
                [formControlName]="'gemeente'"
                label="Gemeente:"
                placeholder="Gemeente"
                [isReadonly]="isReadonlyForm"
              ></ngx-ov-input-field>
            </div>
            <div class="col-span-12">
              <ngx-ov-input-field
                [formControlName]="'wachtwoord'"
                label="Wachtwoord:"
                placeholder="Vul hier je wachtwoord in"
                [isReadonly]="isReadonlyForm"
                [isPassword]="true"
              ></ngx-ov-input-field>
            </div>
            <div class="col-span-12">
              <ngx-ov-toggle
                [label]="'Schrijf u in voor de nieuwsbrief'"
                [formControlName]="'nieuwsbrief'"
                [isReadonly]="isReadonlyForm"
                [attr.data-cy]="'toggle-nieuwsbrief'"
              ></ngx-ov-toggle>
            </div>
            <div class="col-span-12">
              <ngx-ov-3-way-toggle
                [label]="'Bent u geïnteresseerd in reclame?'"
                [formControlName]="'reclame'"
                [isReadonly]="isReadonlyForm"
                [attr.data-cy]="'tree-way-toggle-reclame'"
              ></ngx-ov-3-way-toggle>
            </div>

            <!--
                        Checkbox group:
                            Standaard voorbeeld / voorbeeld met bindLabel en bindValue / voorbeeld met nested bindValue
                        -->
            <div class="col-span-4">
              <ngx-ov-checkbox-group
                [attr.data-cy]="'checkbox-group-readonly'"
                [label]="'Op welke dagen werkt u?'"
                [formControlName]="'werkdagen_empty'"
                [name]="'werkdagen'"
                [options]="checkboxGroupItems"
                [bindLabel]="'label'"
                [isReadonly]="isReadonlyForm"
                [bindId]="'shortLabel'"
                [code]="'shortLabel'"
              >
              </ngx-ov-checkbox-group>
            </div>
            <div class="col-span-8">
              {{ form.get('werkdagen_empty').value | json }}
            </div>
            <div class="col-span-4">
              <ngx-ov-checkbox-group
                [attr.data-cy]="'checkbox-group-werkdagen'"
                [label]="'Op welke dagen werkt u?'"
                [formControlName]="'werkdagen_object_id'"
                [name]="'werkdagen'"
                [options]="checkboxGroupItems"
                [isReadonly]="isReadonlyForm"
                [bindLabel]="'label'"
                [bindId]="'shortLabel'"
                [code]="'shortLabel'"
              >
              </ngx-ov-checkbox-group>
            </div>
            <div class="col-span-8">
              {{ form.get('werkdagen_object_id').value | json }}
            </div>
            <div class="col-span-4">
              <ngx-ov-checkbox-group
                [label]="'Op welke dagen werkt u?'"
                [formControlName]="'werkdagen'"
                [name]="'werkdagen'"
                [options]="checkboxGroupItems"
                [bindLabel]="'label'"
                [isReadonly]="isReadonlyForm"
              >
              </ngx-ov-checkbox-group>
            </div>
            <div class="col-span-8">
              {{ form.get('werkdagen').value | json }}
            </div>
            <div class="col-span-4">
              <ngx-ov-checkbox-group
                [label]="'Op welke dagen werkt u?'"
                [formControlName]="'werkdagen_id'"
                [name]="'werkdagen'"
                [options]="checkboxGroupItems"
                [isReadonly]="isReadonlyForm"
                [bindLabel]="'shortLabel'"
                [bindValue]="'id'"
              >
              </ngx-ov-checkbox-group>
            </div>
            <div class="col-span-8">
              {{ form.get('werkdagen_id').value | json }}
            </div>
            <div class="col-span-4">
              <ngx-ov-checkbox-group
                [label]="'Op welke dagen werkt u? '"
                [formControlName]="'werkdagen_nested_id'"
                [name]="'werkdagen'"
                [options]="checkboxGroupItems"
                [isReadonly]="isReadonlyForm"
                [bindLabel]="'shortLabel'"
                [bindValue]="['nested', 'nested_id']"
              >
              </ngx-ov-checkbox-group>
            </div>
            <div class="col-span-8">
              {{ form.get('werkdagen_nested_id').value | json }}
            </div>
            <div class="col-span-4">
              <ngx-ov-checkbox-group
                [label]="'Op welke dagen werkt u? '"
                [formControlName]="'werkdagen_empty_2'"
                [name]="'werkdagen'"
                [options]="checkboxGroupItems"
                [isReadonly]="isReadonlyForm"
                [bindLabel]="'shortLabel'"
                [bindValue]="'id'"
              >
              </ngx-ov-checkbox-group>
            </div>
            <div class="col-span-8">
              {{ form.get('werkdagen_empty_2').value | json }}
            </div>

            <div class="col-span-4">
              <ngx-ov-checkbox-group
                [label]="
                  'Uit welke dagen bestaat uw weekend? (minstens 2 aanduiden)'
                "
                [formControlName]="'weekend_dagen'"
                [name]="'weekend_dagen'"
                [options]="checkboxGroupItems"
                [isReadonly]="isReadonlyForm"
                [bindLabel]="'shortLabel'"
                [bindValue]="'id'"
              >
              </ngx-ov-checkbox-group>
            </div>
            <div class="col-span-8">
              {{ form.get('weekend_dagen').value | json }}
            </div>

            <div class="col-span-12">
              <ngx-ov-textarea
                [label]="'Opmerkingen'"
                [formControlName]="'opmerkingen'"
                [maxChar]="250"
                [isReadonly]="isReadonlyForm"
                [attr.data-cy]="'textarea-opmerkingen'"
              ></ngx-ov-textarea>
            </div>
            <div class="col-span-12">
              <ngx-ov-checkbox
                [isDisabled]="true"
                [formControlName]="'isDisabled'"
                [label]="'Disabled checkbox'"
                [isReadonly]="isReadonlyForm"
                (valueChange)="onChangeGebruikesvoorwaarden($event)"
              ></ngx-ov-checkbox>
            </div>
            <div class="col-span-12">
              <ngx-ov-checkbox
                [attr.data-cy]="'checkbox-gebruikersvoorwaarden'"
                [isDisabled]="false"
                [formControlName]="'gebruiksvoorwaarden'"
                [label]="
                  'Ik accepteer de <a href=\'https:\/\/www.studietoelagen.be/opvraging-gegevens\' target=\'_blank\'>gebruikersvoorwaarden</a>'
                "
                [isReadonly]="isReadonlyForm"
                [extraInfo]="
                  'Je kan niet verder gaan zonder de gebruiksvoorwaarden goed te keuren'
                "
                (valueChange)="onChangeGebruikesvoorwaarden($event)"
              ></ngx-ov-checkbox>
            </div>
          </ngx-ov-panel>

          <div class="my-10 gap-medium u-position-btn-prim-sec">
            <!-- Krijgen geen outline omdat ze niet voorzien zijn van een (click)-->
            <ngx-ov-button
              [isTertiary]="true"
              [label]="'Patch'"
              (buttonClick)="patch()"
            ></ngx-ov-button>
            <ngx-ov-button
              [isTertiary]="true"
              [label]="'Reset'"
              (buttonClick)="onReset()"
            ></ngx-ov-button>
            <ngx-ov-button
              [isSecondary]="true"
              [label]="'annuleren'"
            ></ngx-ov-button>
            <ngx-ov-button
              [label]="'Volgende >'"
              [isDisabled]="!gebruikersvoorwaarden || !form.valid"
            ></ngx-ov-button>
            {{ form.errors | json }}
            <ngx-ov-button
              [attr.data-cy]="'button-submit'"
              [label]="'Submit'"
              [isDisabled]="form.valid"
              (buttonClick)="markAll()"
            ></ngx-ov-button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

valid ? {{ form.valid }}
{{ form.getRawValue() | json }}
